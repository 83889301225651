<template>
    <admin-dashboard-layout>
        <v-row>
            <v-col class="text-right">
                <v-btn  color="grey" :to="{name: 'admin.apartments.create'}">Create</v-btn>
            </v-col>
        </v-row>
        <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="apartments"
            :items-per-page="15"
            class="elevation-1"
        >
            <template #item.id="{ item }">
                <v-row class="cursor-pointer text--blue">
                    <v-col @click="handleClick(item.id, 'apartments')">
                        {{ item.id }}
                    </v-col>
                </v-row>
            </template>
            <template #item.project_id="{ item }">
                <v-row class="cursor-pointer text--blue" v-if="item.project !== null">
                    <v-col @click="handleClick(item.project, 'projects')">
                        {{ item.project_id }}
                    </v-col>
                </v-row>
            </template>
            <template v-slot:item.created_at="{ item }">
                {{ new Date(item.created_at).toLocaleString() }}
            </template>
            <template v-slot:item.updated_at="{ item }">
                {{ new Date(item.updated_at).toLocaleString() }}
            </template>
            <template v-slot:item.actions="{ item }">
                <v-btn class="mr-2" color="green" dark @click="handleClick(item.id, 'apartments')">
                    Edit
                </v-btn>
                <apartment-delete-dialog @deleted="handleRemoveApartment" :apartment="item" />
            </template>
        </v-data-table>
    </admin-dashboard-layout>
</template>

<script>
import Apartment from '../../../models/Apartment';
import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
import ApartmentDeleteDialog from "../../../components/dialogs/ApartmentDeleteDialog";

export default {
    name: "apartments-index",
    components: {AdminDashboardLayout, ApartmentDeleteDialog},
    data: function () {
        return {
            loading: false,
            apartments: [],
            headers: [
                {
                    text: '# ID',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                },
                {text: 'Project Id', value: 'project_id'},
                {text: 'Bedrooms', value: 'bedrooms'},
                {text: 'Floor', value: 'floor'},
                {text: 'Area', value: 'area'},
                {text: 'Price', value: 'price'},
                {text: 'Created at', value: 'created_at'},
                {text: 'Updated at', value: 'updated_at'},
                {text: 'Actions', value: 'actions'},
            ],
        }
    },
    methods: {
        handleClick(dataId, namePath) {
            this.$router.push({name: `admin.${namePath}.show`, params: {id: dataId}})
        },
        handleRemoveApartment(id) {
            this.apartments = this.$_.filter(this.apartments, apartment => apartment.id !== id);
        }
    },
    async mounted() {
        this.loading = true
        this.apartments = await Apartment.get()
        this.loading = false
    },
}
</script>

<style scoped>
.cursor-pointer {
    cursor: pointer;
}

.text--blue {
    color: #0d47a1;
}

.text--blue:hover {
    text-decoration: underline;
}

</style>
