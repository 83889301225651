var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('admin-dashboard-layout',[_c('v-row',[_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"color":"grey","to":{name: 'admin.apartments.create'}}},[_vm._v("Create")])],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"loading-text":"Loading... Please wait","headers":_vm.headers,"items":_vm.apartments,"items-per-page":15},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"cursor-pointer text--blue"},[_c('v-col',{on:{"click":function($event){return _vm.handleClick(item.id, 'apartments')}}},[_vm._v(" "+_vm._s(item.id)+" ")])],1)]}},{key:"item.project_id",fn:function(ref){
var item = ref.item;
return [(item.project !== null)?_c('v-row',{staticClass:"cursor-pointer text--blue"},[_c('v-col',{on:{"click":function($event){return _vm.handleClick(item.project, 'projects')}}},[_vm._v(" "+_vm._s(item.project_id)+" ")])],1):_vm._e()]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.created_at).toLocaleString())+" ")]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.updated_at).toLocaleString())+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"color":"green","dark":""},on:{"click":function($event){return _vm.handleClick(item.id, 'apartments')}}},[_vm._v(" Edit ")]),_c('apartment-delete-dialog',{attrs:{"apartment":item},on:{"deleted":_vm.handleRemoveApartment}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }